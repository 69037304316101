import React, { FC, useEffect, ReactNode } from 'react';
import fetch from 'isomorphic-fetch';
import ShopifyClient, {
  CustomAttribute,
  Client,
  LineItemToAdd,
  CheckoutResource,
} from 'shopify-buy';
import { useState } from 'react';
import { createContext } from 'react';
import { globalHistory } from '@reach/router';
import Cookies from 'js-cookie';
import {
  SHOPIFY_CHECKOUT_ID_COOKIE,
  SHOPIFY_CHECKOUT_URL_COOKIE,
} from '../constants';

const isProd = process.env.NODE_ENV === 'production';

export const client = ShopifyClient.buildClient(
  {
    domain:
      process.env.GATSBY_SHOPIFY_STORE_CUSTOM_URL ||
      process.env.GATSBY_SHOPIFY_STORE_URL,
    storefrontAccessToken: process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
  },
  // @ts-ignore
  fetch,
);

interface Props {
  loading: boolean;
  addVariantToCart: (
    variantId: string,
    quantity: number,
    customAttributes: CustomAttribute[],
  ) => Promise<void>;
  removeLineItem: (checkoutID: string, lineItemID: string) => Promise<void>;
  updateLineItem: (
    checkoutID: string,
    lineItemID: string,
    quantity: number,
  ) => Promise<void>;
  addDiscount: (checkoutID: string, discountCode: string) => Promise<void>;
  removeDiscount: (checkoutID: string) => Promise<void>;
  addNote: (checkoutID: string, note: string) => Promise<void>;
  updateAttributes: (
    checkoutID: string,
    customAttributes: { key: string; value: string }[],
  ) => Promise<void>;
  client: Client;
  checkout: any;
  didJustAddToCart: boolean;
  checkoutInitialized: boolean;
}

const defaultValues = {
  loading: false,
  checkoutInitialized: false,
  client,
  checkout: {
    lineItems: [],
  },
  didJustAddToCart: false,
  addVariantToCart: async () => {},
  removeLineItem: async () => {},
  updateLineItem: async () => {},
  addDiscount: async () => {},
  removeDiscount: async () => {},
  addNote: async () => {},
  updateAttributes: async () => {},
};

export const StoreContext = createContext<Props>(defaultValues);

const isBrowser = typeof window !== `undefined`;

export const StoreProvider: FC<{ children?: ReactNode }> = ({ children }) => {
  const [checkout, setCheckout] = useState<any>(defaultValues.checkout);
  const [loading, setLoading] = useState<boolean>(false);
  const [checkoutInitialized, setCheckoutInitialized] =
    useState<boolean>(false);
  const [didJustAddToCart, setDidJustAddToCart] = useState<boolean>(false);

  const setCheckoutItem = (checkout: any) => {
    if (isBrowser) {
      const options = {
        ...(isProd ? { domain: '.mikafi.com' } : {}),
      };

      Cookies.set(SHOPIFY_CHECKOUT_ID_COOKIE, checkout.id, options);

      if (checkout?.webUrl) {
        Cookies.set(SHOPIFY_CHECKOUT_URL_COOKIE, checkout.webUrl, options);
      }
    }

    setCheckout(checkout);
  };

  useEffect(() => {
    globalHistory.listen(() => {
      setDidJustAddToCart(false);
    });
  }, []);

  useEffect(() => {
    const initializeCheckout = async () => {
      const existingCheckoutID = isBrowser
        ? Cookies.get(SHOPIFY_CHECKOUT_ID_COOKIE)
        : null;

      if (existingCheckoutID && existingCheckoutID !== `null`) {
        try {
          const existingCheckout = await client.checkout.fetch(
            existingCheckoutID,
          );
          if (!existingCheckout.completedAt) {
            setCheckoutItem(existingCheckout);
            return;
          }
        } catch (e) {
          Cookies.remove(SHOPIFY_CHECKOUT_ID_COOKIE);
        }
      }

      const newCheckout = await client.checkout.create();
      setCheckoutItem(newCheckout);
    };

    initializeCheckout().then(() => setCheckoutInitialized(true));
  }, []);

  const addVariantToCart = async (
    variantId: string,
    quantity: number,
    customAttributes: ShopifyBuy.CustomAttribute[],
  ) => {
    setLoading(true);

    const checkoutID = checkout.id;

    const lineItemsToUpdate: LineItemToAdd[] = [
      {
        variantId,
        quantity,
        customAttributes,
      },
    ];

    return client.checkout
      .addLineItems(checkoutID, lineItemsToUpdate)
      .then(res => {
        setCheckout(res);
        setLoading(false);
        setDidJustAddToCart(true);
        setTimeout(() => setDidJustAddToCart(false), 5000);
      });
  };

  const removeLineItem = async (checkoutID: string, lineItemID: string) => {
    setLoading(true);

    return client.checkout
      .removeLineItems(checkoutID, [lineItemID])
      .then(res => {
        setCheckout(res);
        setLoading(false);
      });
  };

  const updateLineItem = async (
    checkoutID: string,
    lineItemID: string,
    quantity: number,
  ) => {
    setLoading(true);

    const lineItemsToUpdate = [{ id: lineItemID, quantity }];

    return client.checkout
      .updateLineItems(checkoutID, lineItemsToUpdate)
      .then(res => {
        setCheckout(res);
        setLoading(false);
      });
  };

  const addDiscount = async (checkoutID: string, discountCode: string) => {
    setLoading(true);

    return client.checkout
      .addDiscount(checkoutID, discountCode)
      .then(res => {
        setCheckout(res);
        setLoading(false);
      })
      .catch(err => console.log(err));
  };

  const removeDiscount = async (checkoutID: string) => {
    setLoading(true);

    return client.checkout.removeDiscount(checkoutID).then(res => {
      setCheckout(res);
      setLoading(false);
    });
  };

  const addNote = async (checkoutID: string, note: string) => {
    return (
      client.checkout
        // @ts-ignore
        .updateAttributes(checkoutID, {
          note,
        })
        .then((res: CheckoutResource) => {
          setCheckout(res);
        })
    );
  };

  const updateAttributes = async (
    checkoutID: string,
    customAttributes: { key: string; value: string }[],
  ) => {
    setLoading(true);

    return (
      client.checkout
        // @ts-ignore
        .updateAttributes(checkoutID, {
          customAttributes: [...customAttributes],
        })
        // @ts-ignore
        .then(res => {
          setCheckout(res);
          setLoading(false);
        })
        // @ts-ignore
        .catch(err => console.log(err))
    );
  };

  return (
    <StoreContext.Provider
      value={{
        ...defaultValues,
        addVariantToCart,
        removeLineItem,
        updateLineItem,
        addDiscount,
        removeDiscount,
        addNote,
        updateAttributes,
        checkout,
        loading,
        didJustAddToCart,
        checkoutInitialized,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};
