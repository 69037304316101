import React, { FC, ReactNode } from 'react';

import { useState } from 'react';
import { createContext } from 'react';

interface Props {
  openCart: boolean;
  setOpenCart: (open: boolean) => void;
}

const defaultValues = {
  openCart: false,
  setOpenCart: () => {},
};

export const UIContext = createContext<Props>(defaultValues);

export const UIProvider: FC<{ children?: ReactNode }> = ({ children }) => {
  const [openCart, setOpenCart] = useState<boolean>(false);

  return (
    <UIContext.Provider
      value={{
        ...defaultValues,
        openCart,
        setOpenCart,
      }}
    >
      {children}
    </UIContext.Provider>
  );
};
