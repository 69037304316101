exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-account-tsx": () => import("./../../../src/pages/account/[...].tsx" /* webpackChunkName: "component---src-pages-account-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-parterre-coffees-index-tsx": () => import("./../../../src/pages/parterre/coffees/index.tsx" /* webpackChunkName: "component---src-pages-parterre-coffees-index-tsx" */),
  "component---src-pages-parterre-index-tsx": () => import("./../../../src/pages/parterre/index.tsx" /* webpackChunkName: "component---src-pages-parterre-index-tsx" */),
  "component---src-pages-register-tsx": () => import("./../../../src/pages/register.tsx" /* webpackChunkName: "component---src-pages-register-tsx" */),
  "component---src-templates-linktree-tsx": () => import("./../../../src/templates/linktree.tsx" /* webpackChunkName: "component---src-templates-linktree-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-product-tsx": () => import("./../../../src/templates/product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */),
  "component---src-templates-unboxing-tsx": () => import("./../../../src/templates/unboxing.tsx" /* webpackChunkName: "component---src-templates-unboxing-tsx" */)
}

