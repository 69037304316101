import * as React from 'react';
import { IntlProvider } from 'react-intl';

// Translated messages in French with matching IDs to what you declared
const messages = {
  de: {
    de: 'Deutsch',
    en: 'English',
    myMessage: 'Heute ist der {ts, date, ::yyyyMMdd}',
    ground: 'Mahlgrad',
    weight: 'Gewicht',
    addToCart: 'In den Warenkorb',
    adding: 'Wird hinzugefügt',
    buy: 'Kaufen',
    buyCoffee: 'Kaffee kaufen',
    selectOptions: 'Optionen auswählen',
    moreAboutTheBeans: 'Alles zur Bohne',
    country: 'Land',
    location: 'Ort',
    topography: 'Topografie',
    estate: 'Anwesen',
    altitude: 'Höhenlage',
    species: 'Art',
    variety: 'Sorte',
    preparation: 'Prozess',
    traceability: 'Rückverfolgbarkeit',
    specs: 'Spezifikationen',
    details: 'Details',
    story: 'Story',
    coffeeBeans: 'Kaffeebohnen',
    yourCart: 'Dein Warenkorb',
    goToCheckout: 'Zur Kasse',
    redeemVoucher: 'Einlösen',
    delivery: 'Versand',
    calculatedAtCheckout: 'Wird später berechnet',
    subtotal: 'Zwischensumme',
    remove: 'Entfernen',
    from: ' ',
    by: 'von',
    wholeBeans: 'Ganze Bohnen',
    discover: 'Entdecken',
    contactUs: 'Kontaktiere uns',
    designYourOwnRoast: 'Deine eigene Röstung kreieren',
    cart: 'Warenkorb',
    contact: 'Kontakt',
    about: 'Über uns',
    email: 'E-Mail',
    free: 'Gratis',
    AboutMikafi: 'Mikafi Experience',
    discoverMikafisWorld: 'Die Zukunft des Röstens',
    modern: 'Modern',
    modernDescription:
      'Du weisst, was in Sachen guter Stil angesagt ist. Weil du bewusst auf alles in der Welt zugehst, ist Kaffee für dich auch mehr als Kaffee. Du hast deinen eigenen, perfektionierten Geschmack. Reich und offen für fruchtig angenehme Säure. Dann bist du im Mood «Modern» zuhause.',
    modernKeywords: 'Reich und offen für eine fruchtig Säure.',
    classic: 'Klassisch',
    classicKeywords: 'Du magst Kaffee rund, ausgewogen und voll.',
    classicDescription:
      'Du stehst auf zeitlose, starke und authentische Erlebnisse – auch beim Kaffee. Du magst ihn rund, ausgewogen und voll. Allzu «fancy» ist nicht dein Ding, doch die eine oder andere geheimnisvoll dunkle Nuance durchaus. Dann bist du im Mood «Classic» genau richtig',
    funky: 'Funky',
    funkyDescription:
      'Du willst, dass etwas passiert. Das macht dein Leben bunt und aufregend. Kaffee kann das auch: Fermentiert, beginnt ein Schluck mit einem und endet mit einem anderen Geschmack. Nenn es Flavour- Looping. Und falls dich sowas neugierig macht, probier den Mood «Funky».',
    funkyKeywords: 'Achterbahn der Geschmacksnoten.',
    discoverStory: 'Entdecken',
    watchVideo: 'Video anschauen',
    mokka: 'Mokka / Bialetti',
    mokkaBialetti: 'Mokka / Bialetti',
    bialetti: 'Bialetti',
    portafilter: 'Siebträger',
    filter: 'Filter Kaffee',
    aeropress: 'Aeropress',
    automatic: 'Vollautomat',
    espresso: 'Espresso',
    frenchPress: 'French Press',
    roastGrade: 'Röstgrad',
    roastLevel: 'Röstgrad',
    orderText: 'Bestellen,\n erhalten,\n QR-Code scannen,\n eintauchen!',
    aboutMikafi: 'Über Mikafi',
    ourPromise: 'Unser Versprechen',
    stayUpToDate: 'Abonnieren Sie unseren Newsletter',
    subscribeNewsletter: 'Zum Newsletter anmelden',
    subscribe: 'Anmelden',
    coffees: 'Kaffees',
    coffeeDetails: 'Kaffee details',
    suitedFor: 'Ideal für',
    mood: 'Mood',
    tasteNotes: 'Noten',
    acidity: 'Säure',
    body: 'Körper',
    bitterness: 'Intensität',
    goToCart: 'Zum Warenkorb',
    productAdded: 'Produkt wurde zum Warenkorb hinzugefügt!',
    seekingQuality:
      'Wir streben nach Qualität, Wahrheit und Verantwortlichkeit in Sachen Kaffee. Wir versuchen euch die Wertschöpfungskette eures Kaffees so transparent wie möglich weiterzugeben.',
    emptyCart:
      'Sieht aus, als hättest du noch nichts gefunden. Wir verstehen, dass es manchmal schwer ist, sich zu entscheiden.',
    emptyCartAction: 'Zum Shop',
    reorder: 'Nachbestellen',
    feedback: 'Bewerten',
    next: 'Weiter',
    back: 'Zurück',
    yesPlease: 'Ja, gerne',
    chooseRoastGrade: 'Wähle den Röstgrad',
    sameAsUsual: 'Gleich wie gehabt',
    playlistFrom: 'Playlist von',
    storyFrom: 'Geschichte von',
    greetingsFromBrasil: 'Grüsse aus Brasilien',
    designYourOwnCoffee: 'Kreiere deine eigene Röstung',
    playlistText:
      'Eine Playlist zum geniessen von frisch geröstetem italienischen Espresso. Zusammengestellt von Sébastien für seine Mikafi Coffee Club-Röstung.',
    adjustCoffee: 'Willst du dein Kaffee anpassen?',
    adjustCoffeeText:
      'Mit Mikafi kannst du erstmals online mitbestimmen wie dein Kaffee geröstet werden soll. Du hast ihn aktuell als mittlere bis dunkle Röstung vor dir.',
    yourEmail: 'Deine E-Mail-Adresse',
    accept: 'Akzeptieren',
    decline: 'Ablehnen',
    terms:
      'Wir verwenden Cookies, um unsere Dienstleistungen zu verbessern. Weitere Informationen hierzu finden Sie in unseren <link>Datenschutzbestimmungen.</link>',
    orderStandardRoast: 'Standard Röstung bestellen',
    adjustRoastYourself: 'Röstung selber anpassen',
    becomeAClubber: 'Clubber*in werden',
    orderCoffeeSamples:
      'Bitte fügen Sie meiner Bestellung ein kostenloses Kaffeemuster hinzu.',
    roastCoffee: 'Kaffee rösten',
    to: 'bis',
    choiceOfRoastingLevel: 'Wahl von Röstgrad',
    transparency: 'Transparenz',
    taste: 'Geschmack',
    profile: 'Profil',
    description: 'Beschreibung',
    discoverTheFarm: 'Entdecke die Farm',
    unboxingInfoTitle: 'Willkommen in der digitalen Welt deines Kaffees.',
    unboxingInfoText:
      'Wo wir alle wichtigen Informationen für dich aufbereiten und du Inspiration zu deiner kreierten Röstung findest.',
    roastYourOwnCoffee: 'Röste deinen eigenen Kaffee',
    roastYourOwnCoffeeText:
      'Du kannst hier, was bisher unmöglich war: den Röstprozess online mitbestimmen.',
    signUp: 'Werde Mikafi Clubber',
    signIn: 'Anmelden',
    login: 'Willkommen zurück!',
    alreadyHaveAnAccount: 'Hast du bereits ein Konto?',
    dontHaveAnAccount: 'Du hast noch kein Konto?',
    register: 'Werde Mikafi Clubber',
    emailLink: 'Prüfe deine E-Mail auf den magischen Link.',
    yourBenefitsRegister:
      'Sichere dir einen Zugang zur Mikafi Club Lounge. Checke deine Bestellungen und profitiere von Vorteilen.',
    yourBenefitsLogin:
      'Log dich ein, check deine Email und geniesse die Zeit in der Mikafi Lounge.',
    yourBenefits: 'Vorteile',
    yourOrders: 'Bestellungen',
    yourNoOrder:
      'Sie haben noch keine Bestellung. Kaufen Sie Ihren ersten Kaffee',
    yourNoOrderLink: 'hier',
    yourData: 'Benutzerdaten',
    yourPreferences: 'Vorlieben',

    // Tastes
    spices: 'Würzig',
    chocolaty: 'Schokoladig',
    sweet: 'Süss',
    roasty: 'Röstig',
    fruity: 'Fruchtig',
    citrus: 'Zitrusig',
    berry: 'Beerig',
    nutty: 'Nussig',
    grapefruit: 'Grapefruit',
    stonefruit: 'Steinfrucht',
    mildAcidity: 'Milde Säure',
    brightAcidity: 'Helle Säure',
    sharpAcidity: 'Deutliche Säure',
    lowBitterness: 'Wenig Bitterkeit',
    mildBitterness: 'Milde Bitterkeit',
    tartBitterness: 'Herbe Bitterkeit',
    TeaLikeBody: 'Teeartiger Körper',
    fullBody: 'Voller Körper',
    heavyBody: 'Schwerer Körper',
    lightRoast: 'Hell',
    lightMediumRoast: 'Hell – Mittel',
    mediumRoast: 'Mittel',
    mediumDarkRoast: 'Mittel – Dunkel',
    darkRoast: 'Dunkel',
    darkFullRoast: 'Dunkel – Voll',
    fullRoast: 'Voll',

    // Account
    jumbotron: 'Exklusiv für dich.',
    section1Title: 'Gemeinsam',
    section1Text:
      'Mit dem Mikafi Coffee Club wollen wir die Rösterei der Zukunft kreieren. Eine Rösterei bei welcher du mitbestimmst. Damit wir die richtigen Kaffees anbieten und für dich nach deinem Geschmack rösten können, sind wir auf einige Infos angewiesen. Nimm dir doch bitte die Zeit das Formular unter Kaffee-Präferenzen auszufüllen. Zum Dank legen wir dir immer mal wieder passende Samples zu deinen Bestellungen dazu.',

    section3Text:
      'Je mehr du uns mitteilst, desto passender sind unsere Empfehlungen, Muster und Tipps.',

    section3BrewMethod: 'Wie bereitest du normalerweise Kaffee zu?',
    section3BrewMethodOptionFilter: 'Filter',
    section3BrewMethodOptionEspresso: 'Espresso',
    section3BrewMethodOptionBialetti: 'Bialetti',
    section3BrewMethodOptionFrenchPress: 'French Press',
    section3BrewMethodOptionColdBrew: 'Cold Brew',
    section3BrewMethodOptionAeropress: 'Aeropress',

    section3Grind: 'Wie kaufst du deinen Kaffee?',
    section3GrindOptionWholeBeans: 'Ich kaufe ganze Bohnen',
    section3GrindOptionGroundCoffee: 'Ich kaufe gemahlenen Kaffee',

    section3ProficiencyLevel: 'Wie viel Erfahrung hast du mit Kaffee?',
    section3ProficiencyLevelOptionNewbie: 'Ich bin ein Kaffeeneuling',
    section3ProficiencyLevelOptionIntermediate:
      'Ich bin weder Anfänger noch Profi',
    section3ProficiencyLevelOptionAdvanced: 'Ich bin ziemlich fortgeschritten',
    section3ProficiencyLevelOptionExpert: 'Ich bin ein absoluter Kaffee-Nerd',

    section3Additions: 'Fügst du deinem Kaffee etwas hinzu?',
    section3AdditionsOptionNone: 'Nein, ich trinke ihn schwarz',
    section3AdditionsOptionMilkCream: 'Milch oder Rahm',
    section3AdditionsOptionSugarSweetener: 'Zucker oder Süssungsmittel',
    section3AdditionsOptionNonDairyMilk: 'Milch-Alternative',
    section3AdditionsOptionMix: 'Eine Mischung aus den oben genannten',

    section3RoastLevel: 'Welchen Röstgrad bevorzugst du?',
    section3RoastLevelOptionLight: 'Helle Röstung',
    section3RoastLevelOptionMedium: 'Mittlere Röstung',
    section3RoastLevelOptionDark: 'Dunkle Röstung',
    section3RoastLevelOptionVariety: 'Ich mag Abwechslung',
    section3RoastLevelOptionDunno: 'Ich weiss es nicht',

    section3Mood: 'Wie magst du deinen Kaffee?',
    section3MoodOptionClassic: 'Classic (röstig, dunkel, traditionell)',
    section3MoodOptionModern: 'Modern (fruchtig, hell)',
    section3MoodOptionFunky: 'Funky (abwechslungsreich & experimentell)',
    section3MoodOptionVariety: 'Ich mag Abwechslung',
    section3MoodOptionDunno: 'Ich weiss es nicht',

    firstName: 'Vorname',
    lastName: 'Nachname',
    address: 'Adresse',
    additionalAddress: 'Adresszusatz',
    zip: 'Postleitzahl',
    city: 'Stadt',
    phone: 'Telefonnummer',
    company: 'Firma',
    yourAddress: 'Deine Adresse',
    save: 'Speichern',

    // Parterre

    parterreIntroText: 'Parterre x Mikafi',
    parterreTitle: 'Kafi neu entdecken',
    parterreCoffeeTitle: 'Geröstet im Parterre',
    parterreCoffeeSubtitle:
      'Folgende frisch gerösteten Kaffees bieten wir als Bohnen für Zuhause an:',
    parterreSubtitle:
      'Hey - du magst Kaffee? Wir auch! Steile These: du hast noch nie wirklich ein frisches Kaffee Erlebnis in deinem Leben gehabt. Das wollen wir ändern. Denn Kaffee ist nicht einfach gleich Kaffee. Ein Grossteil des Geschmacks und der Intensität geht mit der Lagerung über die Zeit verloren. Deshalb haben wir mit Mikafi einen Röster entwickelt mit dem direkt hier im Parterre geröstet wird. So kannst du Kaffee vom 31.01.22 bis am 26.02.22 im Parterre neu erleben! ',
    parterreFact1Title: 'Täglich frisch geröstet',
    parterreFact1Subtitle:
      ' Diesen Unterschied schmeckst du. Garantiert! Mikafi, das heisst frischen Kaffee lokal erleben. Genau da, wo du normalerweise dein Kaffee trinkst. Und das täglich. ',
    parterreFact2Title: 'Das frische Kaffee Erlebnis',
    parterreFact2Subtitle:
      'Nichts anderes als eine kleine Revolution. Du wirst es lieben. Du möchtest dich beraten lassen? Frag unsere(n) Barista - er/sie hilft dir gerne weiter.',
    parterreFact3Title: 'Try before you buy',
    parterreFact3Subtitle:
      'Du glaubst uns nicht? Überzeug dich selbst - und wenn’s dir schmeckt: Kaufe die frisch gerösteten Bohnen direkt am Counter oder online.',
    parterreRoastsTitle: 'Frisch geröstet',
    parterreRoastsSubTitle:
      'Folgende frisch gerösteten Kaffees bieten wir als zubereitetes Getränk oder als Bohnen für Zuhause an:',
    parterreFaq1Title: 'Wie lang dauert das Pop-up?',
    parterreFaq1Text: 'Ein Monat. Vom 31.01.22 bis am 26.02.22.',
    parterreFaq2Title: 'Wieso macht ihr das im Parterre?',
    parterreFaq2Text:
      'Das Parterre ist ein perfekter Testcase für uns - genau an solchen Orten wird unser Röster künftig das Kafierlebniss revolutionieren. Wir können hier wertvolle Erfahrungen sammeln. Plus sind wir Fans und fühlen uns hier wohl.',
    parterreFaq3Title: 'Was kommt als nächstes?',
    parterreFaq3Text:
      'Wir sind gerade in der Entwicklung der 0-Serie unser Röster - unser Ziel ist es im Oktober 2023, den Service auf dem Markt zu bringen.',
  },
  en: {
    de: 'Deutsch',
    en: 'English',
    myMessage: 'Today is {ts, date, ::yyyyMMdd}',
    ground: 'Ground',
    weight: 'Weight',
    addToCart: 'Add to cart',
    adding: 'Adding',
    buy: 'Buy',
    buyCoffee: 'Buy Coffee',
    selectOptions: 'Select options',
    moreAboutTheBeans: 'More about the beans',
    country: 'Country',
    location: 'Location',
    topography: 'Topography',
    estate: 'Estate',
    altitude: 'Altitude',
    species: 'Species',
    variety: 'Variety',
    preparation: 'Preparation',
    traceability: 'Traceability',
    specs: 'Specs',
    details: 'Details',
    story: 'Story',
    coffeeBeans: 'Coffee Beans',
    yourCart: 'Your Cart',
    goToCheckout: 'Go to checkout',
    redeemVoucher: 'Redeem',
    delivery: 'Delivery',
    calculatedAtCheckout: 'Calculated at checkout',
    subtotal: 'Subtotal',
    remove: 'Remove',
    from: ' ',
    by: 'by',
    wholeBeans: 'Whole beans',
    discover: 'Discover',
    contactUs: 'Contact us',
    designYourOwnRoast: 'Design your own roast',
    cart: 'Cart',
    contact: 'Contact',
    about: 'About',
    email: 'Email',
    free: 'Free',
    AboutMikafi: 'Mikafi Experience',
    discoverMikafisWorld: 'The future of roasting',
    modern: 'Modern',
    modernDescription:
      'You have a fine sense of style. Because you approach everything in the world with awareness, coffee is also more to you than just coffee. You’ve perfected your own taste. You like it rich with a pleasant hint of fruity acidity. The Modern mood is your cup of coffee.',
    modernKeywords: 'Rich with a pleasant hint of fruity acidity.',
    classic: 'Classic',
    classicKeywords: 'You like it well-rounded, full, and balanced.',
    classicDescription:
      'Timeless, powerful, and authentic experiences are your thing – also when it comes to coffee. You like it well-rounded, full, and balanced. It shouldn’t be too fancy, but you have no objection to the odd mysterious, dark nuance. The Classic mood is precisely the right thing for you.',
    funky: 'Funky',
    funkyDescription:
      'You want things to happen. That’s what makes your life colorful and exciting. Coffee can do this too: fermented, with every sip starting with one flavor and ending with another. Call it flavor-looping. If that’s something that piques your curiosity, try out the Funky mood.',
    funkyKeywords: 'Starting with one flavor & ending with another.',
    discoverStory: 'Discover Story',
    watchVideo: 'Watch video',
    mokka: 'Mokka / Bialetti',
    mokkaBialetti: 'Mokka / Bialetti',
    bialetti: 'Bialetti',
    portafilter: 'Portafilter',
    filter: 'Filter Coffee',
    aeropress: 'Aeropress',
    automatic: 'Automatic',
    espresso: 'Espresso',
    frenchPress: 'French Press',
    roastGrade: 'Roast grade',
    roastLevel: 'Roast level',
    orderText: 'Order,\n receive,\n scan QR code,\n dive in!',
    aboutMikafi: 'About Mikafi',
    ourPromise: 'Our promise',
    stayUpToDate: 'Subscribe to our newsletter',
    subscribeNewsletter: 'Subscribe to the Newsletter',
    subscribe: 'Subscribe',
    coffees: 'Coffees',
    coffeeDetails: 'Coffee details',
    suitedFor: 'Suited for',
    mood: 'Mood',
    tasteNotes: 'Tastenotes',
    acidity: 'Acidity',
    body: 'Body',
    bitterness: 'Intensity',
    goToCart: 'Go to cart',
    productAdded: 'Product was added to the cart!',
    seekingQuality:
      'We strive for quality, truth and responsibility in coffee. We try to always be as transparent as possible about the value chain of your coffee.',
    emptyCart:
      "Looks like you haven’t found anything yet. We understand that sometimes it's hard to choose.",
    emptyCartAction: 'Visit the shop',
    reorder: 'Reorder',
    feedback: 'Feedback',
    next: 'Next',
    back: 'Back',
    yesPlease: 'Yes, please',
    chooseRoastGrade: 'Choose a roast grade',
    sameAsUsual: 'Same as usual',
    playlistFrom: 'Playlist from',
    storyFrom: 'Story from',
    greetingsFromBrasil: 'Greetings from Brasil',
    designYourOwnCoffee: 'Design your own roast',
    playlistText:
      'A playlist to drink freshly roasted Italian espresso to. Compiled by Sébastien for his Mikafi Coffee Club roast.',
    adjustCoffee: 'Want to adjust your coffee?',
    adjustCoffeeText:
      'With Mikafi, for the first time you can decide online how your coffee should be roasted. You are currently having a medium - dark roast in front of you.',
    yourEmail: 'Your email',
    accept: 'Accept',
    decline: 'Decline',
    terms:
      'We use cookies to improve our services. To learn more, read our <link>Privacy Policy.</link>',
    orderStandardRoast: 'Order same Roast level again',
    adjustRoastYourself: 'Adjust roast yourself',
    becomeAClubber: 'Become a clubber',
    orderCoffeeSamples: 'Please add free coffee samples to my order.',
    roastCoffee: 'Roast Coffee',
    to: 'to',
    choiceOfRoastingLevel: 'Choice of roasting level',
    transparency: 'Transparency',
    taste: 'Taste',
    profile: 'Profile',
    description: 'Description',
    discoverTheFarm: 'Discover the farm',
    unboxingInfoTitle: 'Willkommen in der digitalen Welt deines Kaffees.',
    unboxingInfoText:
      'Wo wir alle wichtigen Informationen für dich aufbereiten und du Inspiration zu deiner kreierten Röstung findest.',
    roastYourOwnCoffee: 'Roast your own coffee',
    roastYourOwnCoffeeText:
      'Here you can do something that was impossible until now: take part in the roasting process online.',
    signUp: 'Sign up',
    signIn: 'Sign in',
    login: 'Welcome back!',
    alreadyHaveAnAccount: 'Already have an account?',
    dontHaveAnAccount: "Don't have an account yet?",
    register: 'Become a Mikafi Clubber',
    emailLink: 'Check your email for the magic link.',
    yourBenefitsRegister:
      'Get access to the Mikafi Club Lounge. Check your orders and enjoy the benefits.',
    yourBenefitsLogin:
      'Log in, check your email and enjoy your time in the Mikafi Lounge.',
    yourBenefits: 'Benefits',
    yourOrders: 'Orders',
    yourNoOrder: "You don't have any order yet. Buy your first Coffee ",
    yourNoOrderLink: 'here',
    yourData: 'Data',
    yourPreferences: 'Preferences',

    // Tastes
    spices: 'Spices',
    chocolaty: 'Chocolaty',
    sweet: 'Sweet',
    roasty: 'Roasty',
    fruity: 'Fruity',
    citrus: 'Citrus',
    berry: 'Berry',
    nutty: 'Nutty',
    grapefruit: 'Grapefruit',
    stonefruit: 'Stonefruit',
    mildAcidity: 'Mild Acidity',
    brightAcidity: 'Bright Acidity',
    sharpAcidity: 'Sharp Acidity',
    lowBitterness: 'Low Bitterness',
    mildBitterness: 'Mild Bitterness',
    tartBitterness: 'Tart Bitterness',
    TeaLikeBody: 'Tea-like Body',
    fullBody: 'Full Body',
    heavyBody: 'Heavy Body',
    lightRoast: 'Light',
    lightMediumRoast: 'Light – Medium',
    mediumRoast: 'Medium',
    mediumDarkRoast: 'Medium – Dark',
    darkRoast: 'Dark',
    darkFullRoast: 'Dark – Full',
    fullRoast: 'Full',

    // Account
    jumbotron: 'Free stuff for friends.',
    section1Title: 'Together',
    section1Text:
      'With the Mikafi Coffee Club, we want to create the roastery of the future. A roastery in which you have a say. To offer the right coffees and roast them to your taste we’d like to know you better. Please take your time to fill out the form under Coffee Preferences. As a thank you, we will add suitable samples to your orders from time to time.',
    section3Text:
      'The more you let us know, the better our recommendations, samples and tips will be. ',
    section3BrewMethod: 'How do you usually make coffee?',
    section3BrewMethodOptionFilter: 'Filter',
    section3BrewMethodOptionEspresso: 'Espresso',
    section3BrewMethodOptionBialetti: 'Bialetti',
    section3BrewMethodOptionFrenchPress: 'French Press',
    section3BrewMethodOptionColdBrew: 'Cold Brew',
    section3BrewMethodOptionAeropress: 'Aeropress',

    section3Grind: 'How do you buy your coffee?',
    section3GrindOptionWholeBeans: 'I buy whole beans',
    section3GrindOptionGroundCoffee: 'I buy ground coffee',

    section3ProficiencyLevel: 'What is your coffee experience level?',
    section3ProficiencyLevelOptionNewbie: "I'm a coffee newbie",
    section3ProficiencyLevelOptionIntermediate:
      "I'm at an intermediate stage in coffee",
    section3ProficiencyLevelOptionAdvanced: "I'm pretty advanced in coffee",
    section3ProficiencyLevelOptionExpert: "I'm a total coffee nerd",

    section3Additions: 'Do you add anything to your coffee?',
    section3AdditionsOptionNone: 'Nope, I take it black',
    section3AdditionsOptionMilkCream: 'Milk or cream',
    section3AdditionsOptionSugarSweetener: 'Sugar or sweetener',
    section3AdditionsOptionNonDairyMilk: 'Non-Dairy Milk',
    section3AdditionsOptionMix: 'A mix of the above',

    section3RoastLevel: 'What roast level do you typically enjoy?',
    section3RoastLevelOptionLight: 'Light Roast',
    section3RoastLevelOptionMedium: 'Medium Roast',
    section3RoastLevelOptionDark: 'Dark Roast',
    section3RoastLevelOptionVariety: 'I like variety',
    section3RoastLevelOptionDunno: "I don't know",

    section3Mood: 'How do you like your coffee?',
    section3MoodOptionClassic: 'Classic (roasted, dark, traditional)',
    section3MoodOptionModern: 'Modern (fruity, bright)',
    section3MoodOptionFunky: 'Funky (varied & experimental)',
    section3MoodOptionVariety: 'I like variety',
    section3MoodOptionDunno: "I don't know",

    firstName: 'First name',
    lastName: 'Last name',
    address: 'Address',
    additionalAddress: 'Additional address',
    zip: 'Zip',
    city: 'City',
    phone: 'Phone',
    company: 'Company',
    yourAddress: 'Your address',
    save: 'Save',

    // Parterre

    parterreIntroText: 'Parterre x Mikafi',
    parterreTitle: 'Rediscover Coffee',
    parterreCoffeeTitle: 'Roasted at Parterre',
    parterreCoffeeSubtitle:
      'We offer the following freshly roasted coffees as beans for home use:',
    parterreSubtitle:
      'Hey - you like coffee, right? So do we! Steep thesis: youve never really had a fresh coffee experience in your life. We want to change that. Because coffee is not just equal coffee. Much of the flavor and intensity is lost with storage over time. Thats why at Mikafi, we have developed a roaster that roasts coffee right here at Parterre. So you can experience coffee in a new way from the 31.01.22 to the 26.02.22 at Parterre!',
    parterreFact1Title: 'Freshly roasted daily',
    parterreFact1Subtitle:
      'You can taste the difference. Guaranteed! Mikafi means experiencing fresh coffee locally. Right where you normally drink your coffee. And that every day.',
    parterreFact2Title: 'The fresh coffee experience',
    parterreFact2Subtitle:
      'Nothing but a small revolution. You will love it. Want some advice? Ask our barista - he/she will be happy to help you.',
    parterreFact3Title: 'Try before you buy',
    parterreFact3Subtitle:
      'You dont believe us? Convince yourself - and if you like it: Buy the freshly roasted beans directly at the counter or online.',
    parterreRoastsTitle: 'Freshly roasted',
    parterreRoastsSubTitle:
      'We offer the following freshly roasted coffees as prepared beverage or as beans for home use:',
    parterreFaq1Title: 'How long does the pop-up last?',
    parterreFaq1Text: 'One month. From 31.01.22 until 26.02.22.',
    parterreFaq2Title: 'Why are you doing this at Parterre',
    parterreFaq2Text:
      'Parterre is a perfect test case for us - this is exactly the kind of place where our roaster will revolutionize the coffee experience in the future. We can gain valuable experience here. Plus we are fans and feel comfortable here.',
    parterreFaq3Title: 'Whats next?',
    parterreFaq3Text:
      'We are currently developing the 0 series of our roasters - our goal is to launch the service in October 2023.',
  },
};

const wrapPageElement = ({ element, props }) => {
  const locale = props.pageContext.locale;

  return (
    <IntlProvider
      messages={messages[locale]}
      locale={locale}
      key={locale}
      defaultLocale="en"
    >
      {element}
    </IntlProvider>
  );
};

export { wrapPageElement };
