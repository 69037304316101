export const GROUND: string[] = [
  'wholeBeans',
  'portafilter',
  'mokka',
  'frenchPress',
];

export const NOTES: { [key: string]: string } = {
  chocolaty: '#7D4B00',
  nutty: '#C98866',
  roasty: '#F0C374',
  berry: '#92277A',
  fruity: '#FF2C55',
  sweet: '#C3BEFF',
  spices: '#A21833',
  floral: '#F18BD4',
  citrusy: '#FFE178',
  grapefruit: '#F55D4E',
  stonefruit: '#FCA656',
  rum: '#B34039',
  cherry: '#E73451',
  'milk-chocolate': '#692B19',
  apple: '#4EB947',
};

export const ROAST_LEVELS: { [key: string]: number } = {
  light: 1,
  lightMedium: 2,
  medium: 3,
  mediumDark: 4,
  dark: 5,
  darkFull: 6,
  full: 7,
};

export const MOODS: { [key: string]: any } = {
  modern: { bg: 'bg-moods-modern', border: 'border-moods-modern' },
  classic: { bg: 'bg-moods-classic', border: 'border-moods-classic' },
  funky: { bg: 'bg-moods-funky', border: 'border-moods-funky' },
};

export const MIKAFI_TASTE_BUDS_URL = 'https://mikafitastebuds.vercel.app';

export const SHOPIFY_CUSTOMER_TOKEN_COOKIE = 'shopify_customerToken_cc';

export const SHOPIFY_CHECKOUT_ID_COOKIE = 'shopify_checkoutId_cc';

export const SHOPIFY_CHECKOUT_URL_COOKIE = 'shopify_checkoutUrl_cc';

export const SHOPIFY_COOKIE_EXPIRE = 30;

export const API_URL = `https://${process.env.GATSBY_SHOPIFY_STORE_URL}/api/2022-04/graphql.json`;

export const API_TOKEN = process.env.GATSBY_STOREFRONT_ACCESS_TOKEN;
