import React from 'react';

import { StoreProvider } from './src/context/storeContext';
import { UIProvider } from './src/context/uiContext';
import { UserContextProvider } from './src/context/userContext';
import { createClient } from '@supabase/supabase-js';
import { SessionContextProvider } from '@supabase/auth-helpers-react';

// include styles from the ui package
import '@mikafi/ui/fonts.css';

import './src/styles/global.css';

export { wrapPageElement } from './src/wrap-page-element';

const supabaseUrl = process.env.GATSBY_SUPABASE_URL;
const supabaseAnonKey = process.env.GATSBY_SUPABASE_ANON_KEY;
const supabaseClient = createClient(supabaseUrl, supabaseAnonKey);

export const wrapRootElement = ({ element }) => (
  <SessionContextProvider supabaseClient={supabaseClient}>
    <UserContextProvider>
      <StoreProvider>
        <UIProvider>{element}</UIProvider>
      </StoreProvider>
    </UserContextProvider>
  </SessionContextProvider>
);
