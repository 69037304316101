import React from 'react';
import { createContext } from 'react';
import {
  useUser as useSupaUser,
  useSessionContext,
  User,
} from '@supabase/auth-helpers-react';

type UserContextType = {
  accessToken: string | null;
  user: User | null;
  isLoading: boolean;
};

export const UserContext = createContext<UserContextType | undefined>({
  accessToken: null,
  user: null,
  isLoading: false,
});

export interface Props {
  [propName: string]: any;
}

export const UserContextProvider = (props: Props) => {
  const { session, isLoading } = useSessionContext();
  const user = useSupaUser();
  const accessToken = session?.access_token ?? null;

  const value = {
    user,
    isLoading,
    accessToken,
  };

  return <UserContext.Provider value={value} {...props} />;
};
